'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:CartCtrl
 * @description
 * # CheckoutCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('CheckoutCtrl', function ($scope, $location, $uibModal, Analytics, session, api, COUNTRIES) {

		const startTime = new Date().getTime();

		function extendedPrice(item) {
			return item.unitPrice * item.qty;
		}

		$scope.companyInputTitle = function () {
			return $scope.cart.companyOverride ?
				'Upgrades must be registered to the same company name' :
				!$scope.isFree() ?
					'License will be registered to this company' :
					'';
		};

		$scope.totalWithoutPromo = function () {
			return cartActionLineItemSum(extendedPrice);
		};

		$scope.totalOrderPrice = function () {
			return $scope.totalWithoutPromo() - $scope.promoDollarAmount();
		};

		$scope.isFree = function () {
			return $scope.totalOrderPrice() === 0;
		};

		$scope.isInvalidPaymentMethod = () => {
			return !$scope.isFree() && $scope.paymentData.paymentMethod !== 'creditCard' && $scope.cart.autoRenew;
		};

		$scope.invalidPaymentMethodMessage = () => {
			return 'Auto-renew is only supported for Credit Card payment types. Please change your payment type, or change your "Auto-Renew" choice at the top of the page.';
		};

		$scope.promoName = function () {
			const promo = $scope.cart.promoCode;
			if (!promo) {
				return null;
			} else if (promo.affiliate) {
				return 'Affiliate code for ' + promo.affiliateUser.company;
			} else {
				return promo.name;
			}
		};

		$scope.promoDollarAmount = function () {
			const promo = $scope.cart.promoCode;
			if (!promo) {
				return null;
			}
			if (promo.affiliate && !session.account) {
				return null; // must log in to apply affiliate code
			} else if ($scope.cart.commissionChoice === 'Check') {
				return 0; // no discount
			} else if (promo.creditAmount) {
				return promo.creditAmount;
			} else if (promo.creditPercent) {
				if (!promo.variant) { // discount applies to entire order
					return Math.round($scope.totalWithoutPromo() * promo.creditPercent) / 100;
				} else { // discount applies to a single item
					const eligibleItem = $scope.cart.cartItems.find(function (action) {
						return action.variantId === promo.variant.id;
					});
					return Math.round(extendedPrice(eligibleItem) * promo.creditPercent) / 100;
				}

			} else {
				return null; // something fishy about this one
			}
		};

		// function eligibleForAutoRenew(i) {
		// 	return i.product.version.eligibleForAutoRenew &&
		// 		i.product.variantType.code !== 'RENEWAL' &&
		// 		i.product.variantType.code !== 'DEMO' &&
		// 		i.product.variantType.code !== 'FREE';
		// }

		/**
		 * @param callbackFn returns an amount for a cartAction's lineItem
		 */
		function cartActionLineItemSum(callbackFn) {
			return $scope.cartActions
				.reduce(function (sum, eachAction) {
					try {
						return sum + eachAction.lineItems.reduce(function (subSum, lineItem) {
							return subSum + callbackFn(lineItem) || 0;
						}, 0);
					} catch (e) {
						console.error('Could not evaluate', callbackFn, 'on', $scope.cartActions, e);
						return 0;
					}
				}, 0);
		}

		$scope.manualRenewPrice = function () {
			return cartActionLineItemSum(function (item) {
				return item.renewalPrice;
			});
		};

		$scope.autoRenewPrice = function () {
			return $scope.manualRenewPrice() * 0.9;
		};

		$scope.buyingForCustomer = function () {
			$scope.tmp.buyingFor = 'customer';
			$uibModal.open({
				controller: 'ModalResellerCheckoutCtrl',
				templateUrl: 'views/modal-reseller-checkout.html',
				size: 'lg',
				scope: $scope,
			}).result.catch(function () {
				$scope.tmp.buyingFor = null;
			});
		};

		// $scope.removeCartItem = function (index) {
		// 	session.removeCartItem(index);
		// 	redirectIfCartIsEmpty();
		// };

		// event sent from shopping-cart component
		$scope.$on('remove-cart-action', function (event, cartAction) {
			session.removeCartAction(cartAction);
			redirectIfCartActionsIsEmpty();
		});

		// event sent from shopping-cart component
		$scope.$on('remove-promo', function () {
			$scope.cart.promoCode = null;
		});

		$scope.applyPromoCode = function (code) {
			api.getPromoCode(code).then(function (promo) {
				Analytics.trackEvent('promo', 'success', code, promo.name);
				//I'm disabling the select_promotion event for applying promo codes, because this isn't what select_promotion is for, and it confused GA4 --wgs
				//Analytics.select_promotion(promo.name, promo.code);
				if (promo.affiliate && !$scope.cart.commissionChoice) {
					$scope.buyingForCustomer(); // must log in and choose
				} else {
					$scope.cart.promoCode = promo;
				}
				toastr.success('Your promo code has been applied!', 'Promotional Code');
			}, function (error) {
				$scope.cart.promoCode = null;
				Analytics.trackEvent('promo', 'fail', code, error);
			});
		};

		$scope.submit = function () {
			$scope.myForm.$setSubmitted();
			if ($scope.myForm.$invalid) {
				if ($scope.myForm.$error.ccExp) {
					toastr.warning('Credit card expiration must be in the future');
				} else {
					toastr.warning('Please fix validation errors');
				}
				return;
			}
			if ( $scope.isInvalidPaymentMethod() ) {
				return toastr.warning($scope.invalidPaymentMethodMessage(), 'Invalid Payment Method / Auto-Renew');
			}
			const tmpCart = angular.copy($scope.cart); // assemble the pieces into something back-end-friendly
			const cc = tmpCart.paymentDatas[0].creditCard;
			if (tmpCart.paymentDatas[0]?.paymentMethod==='creditCard' && cc && $scope.totalOrderPrice() > 0.0) {
				cc.ccNumber = $scope.tmp.ccNumber;
				cc.securityCode = $scope.tmp.cvv;
				// cc.expiration = $scope.creditCard.expirationYear + '-' + $scope.creditCard.expirationMonth + '-01';
				cc.cardType = $scope.myForm.cardNumber.$ccType;
				if (cc.ccExpirationYear.length === 2) {
					cc.ccExpirationYear = parseInt(cc.ccExpirationYear) + 2000;
				}
			}

			const progress = $uibModal.open({
				templateUrl: 'views/modal-checking-out-progress.html',
				size: 'lg',
				scope: $scope,
			});

			const timeSpent = new Date().getTime() - startTime;
			api.checkout({cartActions: $scope.cartActions, cart: tmpCart}).then(function (/**CheckoutResponse*/checkoutResponse) {
				Analytics.purchase(tmpCart, $scope.cartActions, checkoutResponse);
				Analytics.trackEvent('cart', 'timeToCheckout', 'elapsedTime', timeSpent, null);
				if (tmpCart.autoRenew) {
					Analytics.join_group('autoRenew');
				}
				if (tmpCart.paymentDatas?.[0]?.person?.sendUpdates) {
					Analytics.join_group('receiveUpdates');
				}

				session.clearCart();
				$location.path('/receipt')
					.search('cartId', checkoutResponse.cartId)
					.search('licenseKey', checkoutResponse.licenseKey)
					.search('registeredTo', checkoutResponse.registeredTo);
				if (checkoutResponse.warnings) {
					checkoutResponse.warnings.forEach(function (m) {
						toastr.warning(m.message, m.title);
					});
				}
			}, function () {
				// $scope.tmp.buyingFor = null;
				progress.close();
			}).finally(progress.close);
		};

		$scope.generateQuote = () => {
			if (!$scope.person.company && !$scope.paymentData.purchasingCompany) {
				return toastr.warning('Please enter a company name');
			}
			if (!window.confirm('This will generate a quote for your cart items. Do you want to continue?')) {
				return;
			}
			const progress = $uibModal.open({
				templateUrl: 'views/modal-checking-out-progress.html',
				size: 'lg',
				scope: $scope,
			});
			const tmpCart = angular.copy($scope.cart); // assemble the pieces into something back-end-friendly
			Analytics.trackEvent('cart', 'generateQuote');
			api.generateQuote({cartActions: $scope.cartActions, cart: tmpCart}).then(function (/**string*/quoteUrl) {
				window.location.href = quoteUrl;
			}, function () {
				progress.close();
			}).finally(progress.close);
		};

		/*
		///////////////////////////////////////////////////////////////////////////
		// INIT
		///////////////////////////////////////////////////////////////////////////
		*/
		$scope.countries = COUNTRIES;

		function redirectIfCartActionsIsEmpty() {
			if ($scope.cartActions.length === 0) {
				toastr.info('Your cart is empty!');
				$location.path('/');
			}
		}

		$scope.tmp = {
			// buyingFor: undefined, // 'self', // FIX! turn this back on
			buyingFor: 'self',
			paymentMethod:'CreditCard'
		};
		/**
		 * @type {CartAction[]}
		 */
		$scope.cartActions = session.cartActions;
		Analytics.begin_checkout($scope.cartActions);

		redirectIfCartActionsIsEmpty();

		$scope.isPaymentSelectEnabled = () => {
			return $location.search()?.['enable-beta-features'];
		};

		$scope.cart = {autoRenewFailed: false, status: 0};

		api.getCartForCheckout($scope.cartActions)
			.then(cart => {
				/**
				 * @type {ShoppingCart}
				 */
				$scope.cart = cart;
			})
			.then(() => {
				// if (!$scope.cart.paymentDatas || $scope.cart.paymentDatas.length === 0) {
				// 	$scope.cart.paymentDatas = [{}];
				// }
				$scope.paymentData = $scope.cart.paymentDatas[0];
				// if (!$scope.paymentData.person) {
				// 	$scope.paymentData.person = {};
				// }
				if ($scope.cart.companyOverride) {
					$scope.paymentData.person.company = $scope.cart.companyOverride; // locked
					$scope.tmp.buyingFor = 'self'; // cannot switch upgrade to another company
				}
				$scope.person = $scope.paymentData.person;
				// if ($scope.person.sendUpdates === undefined || $scope.person.sendUpdates === null) {
				// 	$scope.person.sendUpdates = true; // due to europe law, we need to wait for them to specify
				// }
				if (!$scope.paymentData.creditCard) {
					$scope.paymentData.creditCard = {};
				}
				$scope.creditCard = $scope.paymentData.creditCard;

				$scope.disableFields = false;

				if ($scope.isFree()) {
					$scope.tmp.buyingFor = 'self'; // no need to ask
				}
			});

	});
