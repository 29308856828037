'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:AdminCtrl
 * @description
 * # AdminCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('AdminCtrl', function ($scope, $location, $uibModal, Analytics, api, user) { // FIX!! $location stuff is all broken. Much of the api call stuff is broken. Use the legacy site for now.
		$scope.user = user;
		$scope.vm = this;
		$scope.search = $location.search();
		$scope.today = new Date().toLocaleDateString('sv-SE');
		$scope.aff = {
			dateFrom: window.moment().startOf('quarter').format('M/D/YYYY'),
			dateTo: window.moment().endOf('quarter').format('M/D/YYYY')
		};
		$scope.sortModelUsers = {};
		$scope.sortModelPromos = {};
		$scope.sortModelProducts = {};
		$scope.sortModel = {};
		$scope.reportFilter = {
			dateFrom: window.moment().startOf('month').format('M/D/YYYY'),
			dateTo: window.moment().endOf('month').format('M/D/YYYY')
		};
		$scope.receiptHost = DEVMODE ? 'http://localhost:8080' : '';
		/**
		 * @type {ProductLine[]}
		 */
		$scope.products = [];
		/**
		 * @type {VariantType[]}
		 */
		$scope.variantTypes = [];

		/**
		 * @type {ValueListItem[]}
		 */
		$scope.keyGeneratorOptions = [
			{value: 'com.prosc.store.model.license.AddOnLicenseKeyGenerator', name: 'AddOn'},
			// {value: 'com.prosc.store.model.license.DefaultLicenseKeyGenerator', name: 'Default'}, // just leave it empty
			{value: 'com.prosc.store.model.license.FreeLicenseKeyGenerator', name: 'Free'},
			{value: 'com.prosc.store.model.license.HiddenLicenseKeyGenerator', name: 'Hidden'},
			{value: 'com.prosc.store.model.license.SuperContainerHostingLicenseKeyGenerator', name: 'SuperContainer Hosting'},
		];

		/**
		 * @param variantType {VariantType}
		 */
		$scope.iconForVariantType = (variantType) => {
			switch(variantType.code || '') {
				case 'DEMO': return 'fa-cloud-download';
				case 'RENEWAL': return 'fa-refresh';
				case 'SINGLE_USER': return 'fa-user';
				case 'DEFAULT': return 'fa-users';
				case 'ENTERPRISE': return 'fa-building';
				case 'DEVELOPER_ENTERPRISE': return 'fa-globe';
				case 'DEVELOPER': return 'fa-suitcase';
				default: return 'fa-question-circle';
			}
		};

		/**
		 * @param urlParams
		 */
		$scope.authorizeSearchUrl = (params) => {
			return 'https://account.authorize.net/UI/themes/anet/merch.aspx?page=search&StartBatch=958879601&EndBatch=815319589&' + params;
		};

		const apiGet = function (url, params, successCallback) {
			return api.get('admin/' + url, {params: params}).then(successCallback);
		};

		$scope.apiGet = apiGet;

		$scope.didSelectTab = function (whichTab) {
			if (whichTab === 'home' && !$location.search().tab) {
				return; // don't set the tab
			}
			$location.search('tab', '' + whichTab);
		};

		const showTab = function (index) {
			$scope.active = index;
			$location.search('tab', '' + index);
		};

		$scope.userSearch = function (q) {
			if (q) {
				$location.search('userQuery', q);
				apiGet('userSearch', {q: q}, function (response) {
					$scope.users = response;
				});
			}
		};

		$scope.userFetchOrders = function (u) {
			apiGet('orders', {email: u.email, registeredTo: u.company, logicalOperator: 'OR'}, function (response) {
				$scope.orders = response;
				showTab('orders');
			});
		};

		$scope.userFetchLinkedLicenses = function (u) {
			apiGet('orders', {accountId: u.id}, function (response) {
				$scope.orders = response;
				showTab('orders');
			});
		};

		$scope.userSwitchUser = function (u) {
			apiGet('su', {userId: u.id}, function () {
				toastr.success('You are logged in as ' + u.name);
			});
		};

		$scope.updatePaymentInfo = async (/*ShoppingCart*/ o) => {
			const licenseKey = await api.generateLicenseKeyForOrderId(o.id);
			$location.path('/update-payment-info/' + licenseKey);
		};

		$scope.orderRenewModal = async function (/*ShoppingCart*/ o) {
			await $uibModal.open({
				controller: 'OrderRenewCtrl',
				scope: $scope,
				templateUrl: 'orderRenew.html',
				size: 'lg',
				resolve: {
					order: o,
					details: apiGet('renew-preview', {orderId: o.id})
				}
			}).result;
			let newCart = await api.adminRenewProcess(o);
			toastr.success('Order #' + o.id + ' was successfully renewed by order #' + newCart.id, 'Renewal Successful');
		};

		$scope.orderSearch = function (q) {
			$location.search('orderQuery', q);
			$scope.activeTab = 'orders';
			if (q) {
				apiGet('orders', {q: q}, function (response) {
					$scope.orders = response;
					if ($scope.orders.length === 0) {
						window.toastr.warning('No matching orders found', 'Empty Results', {timeOut:1000});
					}
				});
			}
		};

		/**
		 * @param o {ShoppingCart}
		 */
		const _orderEdit = async function (o) {
			o.cartItems[0].rootCartItem = await api.get('admin/root-cart-item', {params: {cartItemId: o.cartItems[0].id}});
			return $uibModal.open({
				controller: 'OrderEditCtrl',
				scope: $scope,
				templateUrl: 'orderEdit.html',
				size: 'lg',
				resolve: {
					order: o
				}
			}).result;
		};

		/**
		 * @param {ShoppingCart} cart
		 */
		$scope.orderEdit = function (cart) {
			let orderId = cart?.id;
			if (!orderId) {
				throw 'Order ID not specified';
			}
			apiGet('orders', {cartId: orderId}, async function (response) {
				let saved = await _orderEdit(response[0]);
				if (saved.id === cart.id) { // only if we didn't navigate to a different order
					angular.copy(saved, cart);
				}
			});
		};

		$scope.orderEditCartItemId = function (cartItemId) {
			if (!cartItemId) {
				throw 'cartItemId not specified';
			}
			apiGet('orders', {cartItemId: cartItemId}, function (response) {
				_orderEdit(response[0]);
			});
		};

		$scope.affiliateQuery = function (params) {
			apiGet('affiliate-orders', params, function (response) {
				if (response.length === 0) {
					toastr.warning('No matching records found');
				}
				$scope.affiliateOrders = response;
				const payouts = {};
				$scope.affiliateOrders.forEach(function (x) {
					const affiliate = x.promo_code_name;
					const amount = x.extended_price_stored_before_promo - x.extended_price_stored;
					payouts[affiliate] = (payouts[affiliate] || 0) + amount;
				});
				$scope.affiliatePayouts = payouts;
			});
		};

		$scope.editProduct = function (product) {
			return $uibModal.open({
				controller: 'ModalProductEditCtrl',
				scope: $scope,
				templateUrl: 'views/modal-product-edit.html',
				size: 'lg',
				resolve: {
					product: product.id ? apiGet('product', {id: product.id}) : product
				}
			}).result
				.then(function (updated) {
					angular.copy(updated, product);
					return updated;
				});
		};

		$scope.productAdd = () => $scope.editProduct({versions:[{versionNumber:1, name:'1.0', variants:[]}]})
			.then(saved => {
				 $scope.productsAll.push(saved);
				 $scope.products.push(saved);
			}) ;

		$scope.productFetchCsv = function (/*product*/) {
			window.alert('Not implemented yet'); // FIX!!!!
			// window.location.href = '../API/productEmailCsv?id=' + product.id;
		};

		$scope.productAddUser = function (/*product*/) {
			window.alert('Not implemented yet'); // FIX!!!!
			// $scope.userToAdd = window.prompt('Enter a Full Name <email@domain.com> to add to the product email list.', $scope.userToAdd || '');
			// if ($scope.userToAdd) {
			// 	apiGet('productAddUser', {productCode: product.code, email: $scope.userToAdd}, function () {
			// 		window.alert($scope.userToAdd + ' has been added to ' + product.name);
			// 		$scope.userToAdd = null;
			// 	});
			// }
		};

		function runReport(reportName, filter) {
			$scope.reportData = null;
			$scope.apiGet(reportName, filter, function (response) {
				$scope.reportData = response;
				if (response.length === 0) {
					window.alert('No records found in this date range');
				}
			});
		}

		$scope.renewalReport = function (filter) {
			runReport('renewal-report', filter);
		};

		$scope.affiliateSignupReport = function (filter) {
			runReport('affiliate-signup-report', filter);
		};

		/*
		///////////////////////////////////////////////////////////////////////////
		// PROMO CODES
		///////////////////////////////////////////////////////////////////////////
		*/
		$scope.promoSearch = function (q) {
			$location.search('promoQuery', q);
			apiGet('promo-codes', {q: q})
				.then(function (found) {
					$scope.promos = found;
				});
		};

		$scope.promoDescription = function (promo) {
			if (promo.creditAmount) {
				return '$' + promo.creditAmount;
			} else if (promo.creditPercent) {
				return promo.creditPercent + '%';
			} else {
				return '???';
			}
		};

		$scope.promoAccountDescription = function (promo) {
			if (promo.account) {
				return promo.account.name;
			} else if (promo.company) {
				return promo.company;
			} else {
				return null;
			}
		};

		$scope.promoFetchOrders = function (p) {
			$scope.orderSearch('promoCode:' + p.code);
		};

		$scope.promoEdit = function (o) {
			$uibModal.open({
				controller: 'PromoEditCtrl',
				scope: $scope,
				templateUrl: 'promoEdit.html',
				size: 'md',
				resolve: {
					promo: o
				}
			});
		};

		$scope.promoAdd = function () {
			$scope.promoEdit({});
		};


		/*
		///////////////////////////////////////////////////////////////////////////
		// INIT
		///////////////////////////////////////////////////////////////////////////
		*/
		const refreshCurrentTab = function () {
			const search = $location.search();
			if (search.userQuery && $scope.activeTab === 'users') {
				$scope.userSearch(search.userQuery);
			}
			if (search.orderQuery && $scope.activeTab === 'orders') {
				$scope.orderSearch(search.orderQuery);
			}
			if (search.promoQuery && $scope.activeTab === 'promos') {
				$scope.promoSearch(search.promoQuery);
			}
		};

		if ($scope.search.tab) {
			$scope.activeTab = $scope.search.tab || 0; // which tab
		}

		refreshCurrentTab();

		// listen for back/forward button
		$scope.$on('$routeUpdate', function () {
			const search = $location.search();
			$scope.search = search;
			$scope.activeTab = search.tab || 'home';
			// refreshCurrentTab(); // was causing searches to happen twice
		});

		apiGet('products', null, function (response) {
			const cutoff = Date.now() - 86400000 * 30;
			$scope.productsAll = response;
			$scope.products = response.filter(p => p.featuredBoolean || p.dateCreated > cutoff || p.dateModified > cutoff);
		});
		apiGet('variant-types', null, function (response) {
			$scope.variantTypes = response;
		});

	})
	.controller('OrderEditCtrl', function ($scope, api, order) {
		$scope.originalOrder = order;
		$scope.order = angular.copy(order);

		$scope.save = function () {
			api.saveOrder($scope.order)
				.then(function (response) {
					angular.copy(response, $scope.originalOrder);
					$scope.$close($scope.order);
					toastr.success('Your changes have been saved');
				});
		};

		$scope.editCartItemId = function (cartItemId) {

			$scope.apiGet('orders', {cartItemId: cartItemId}, function (response) {
				const data = response;
				if (!data.length) {
					window.alert('No order found for cartItemId ' + cartItemId);
					return;
				}
				$scope.order = $scope.originalOrder = data[0];
			});
		};

		$scope.addSupportRecord = async function (cartItem) {
			if (!cartItem.supports) {
				cartItem.supports = [];
			}
			// /**
			//  * @type {ProductVariant}
			//  */
			// let pv = cartItem.product;
			cartItem.supports.push({
				orderDate: moment($scope.order.dateCreated).format('YYYY-MM-DD'),
				maxReleaseDate: null,
				notes: 'Manually created by ' + $scope.user.email,
				// productId: ???, we cannot get from a cartItem to a version / product
				// variant: pv.variantType.byteValue, // may be a renewal, and we don't want to fuss with fetching. Instead, just require the user to enter this info.
				// version: ???, we cannot get from a cartItem to a version / product
			});
		};


	})
	.controller('OrderRenewCtrl', function ($scope, api, order, details) {
		$scope.order = order;
		$scope.details = details;

	})
	.controller('PromoEditCtrl', function ($scope, api, promo) {
		$scope.originalPromo = promo;
		$scope.promo = angular.copy(promo);
		$scope.save = function () {
			api.savePromoCode($scope.promo)
				.then(function (response) {
					angular.copy(response, $scope.originalPromo);
					$scope.$close($scope.promo);
					toastr.success('Your changes have been saved');
				});
		};

		$scope.quickSearchVariants = function (q) {
			return api.get('api/quicksearch-variant', {params: {q: q}});
		};

	}).directive('proscTableData', function () {
	function beautify(s) {
		// add a space between (lowercase) and (uppercase + lowercase)
		if (s === 'id') {
			return 'ID';
		}
		return s.substr(0, 1).toUpperCase() +
			s.substr(1)
				.replace(/_([a-z])/, function (x, m) {
					return ' ' + m.toUpperCase();
				})
				.replace(/([a-z])([A-Z][a-z])/g, '$1 $2');
	}

	return {
		template: '<table class="table table-sm table-striped">' +
			'<thead><tr><th ng-repeat="c in columns">{{c.name}}</th></tr></thead>' +
			'<tbody><tr ng-repeat="o in rows">' +
			'<td ng-repeat="c in columns">{{o[c.key]}}</td>' +
			'</tr></tbody>' +
			'</table>        ',
		restrict: 'A',
		scope: {
			proscTableData: '=',
		},
		link: function (scope) {
			scope.$watch('proscTableData', function () {
				if (!scope.proscTableData || scope.proscTableData.length === 0) {
					// nothing bound yet, or it's been reset/cleared
					scope.rows = scope.columns = null;
				} else if (scope.proscTableData.rows) {
					// this is JDBCQueryData
					scope.rows = scope.proscTableData.rows;
					scope.columns = scope.proscTableData.columnNames.map(function (s) {
						return {key: s, name: beautify(s)};
					});
				} else if (scope.proscTableData.length) {
					// array of objects
					scope.columns = Object.keys(scope.proscTableData[0]).map(function (o) {
						return {key: o, name: beautify(o)};
					});
					scope.rows = scope.proscTableData;
				} else {
					console.warn('Wrong data type fo proscTableData, expected array of objects or JDBCHelper query data');
					scope.rows = null;
					scope.columns = null;
				}
			});
		},
		controller: function () {
		}

	};
});
